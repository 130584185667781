$grid-breakpoints: (
  xs: 0,
  // handset portrait (small, medium, large) | handset landscape (small)
  sm: 600px,
  // handset landscape (medium, large) | tablet portrait(small, large)
  // md: 960px, //  tablet landscape (small, large)
  md: 1025px,
  // Everything under 1025px is considered small.. 1024px ipad landscape is the smallest we support
  lg: 1280px,
  // laptops and desktops
  xl: 1600px // large desktops,,,,,,,,,
);

$container-max-widths: (
  // sm: 600px,
  // md: 960px,
  md: 1024px,
  lg: 1280px,
  xl: 1600px
);
