@use '@angular/material' as mat;

.dense-form-field {
  @include mat.form-field-density(-5);
  @include mat.button-toggle-density(-3);
  @include mat.datepicker-density(-5);
  @include mat.icon-density(-5);
  @include mat.icon-button-density(-5);
}

.select-dense-5 {
  @include mat.select-density(-5);
  @include mat.form-field-density(-5);
}
